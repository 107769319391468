import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './index.module.css'
import logo from './PGraph1@2x.png'
import logo1 from './pIcon1@2x.png'
import logo2 from './pIcon2@2x.png'
import logo3 from './pIcon3@2x.png'

const SDK = () => (
  <div className={styles.wrapper} id="sdk">
    <div className={styles.top}>
      <img src={logo} alt="onChainVoting" />
      <div className={styles.topText}>
        <h3>
          <FormattedMessage id="product.sdk.title" />
        </h3>
        <p>
          <FormattedMessage id="product.sdk.slogan" />
        </p>
      </div>
    </div>
    <h5>
      <FormattedMessage id="productHighlight" />
    </h5>
    <div className={styles.bottom}>
      <div className={styles.module}>
        <img src={logo1} alt="" />
        <h4>
          <FormattedMessage id="product.sdk.title1" />
        </h4>
        <p>
          <FormattedMessage id="product.sdk.desc1" />
        </p>
      </div>
      <div className={styles.module}>
        <img src={logo2} alt="" />
        <h4>
          <FormattedMessage id="product.sdk.title2" />
        </h4>
        <p>
          <FormattedMessage id="product.sdk.desc2" />
        </p>
      </div>
      <div className={styles.module}>
        <img src={logo3} alt="" />
        <h4>
          <FormattedMessage id="product.sdk.title3" />
        </h4>
        <p>
          <FormattedMessage id="product.sdk.desc3" />
        </p>
      </div>
    </div>
  </div>
)

export default SDK
