import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './index.module.css'
import logo from './PGraph3@2x.png'
import logo1 from './pIcon7@2x.png'
import logo2 from './pIcon8@2x.png'
import logo3 from './pIcon9@2x.png'

const Analysis = () => (
  <div className={styles.wrapper} id="analysis">
    <div className={styles.top}>
      <img src={logo} alt="onChainVoting" />
      <div className={styles.topText}>
        <h3>
          <FormattedMessage id="product.analysis.title" />
        </h3>
        <p>
          <FormattedMessage id="product.analysis.slogan" />
        </p>
      </div>
    </div>
    <h5>
      <FormattedMessage id="techSkill" />
    </h5>
    <div className={styles.bottom}>
      <div className={styles.module}>
        <img src={logo1} alt="" />
        <h4>
          <FormattedMessage id="product.analysis.title1" />
        </h4>
        <p>
          <FormattedMessage id="product.analysis.desc1" />
        </p>
      </div>
      <div className={styles.module}>
        <img src={logo2} alt="" />
        <h4>
          <FormattedMessage id="product.analysis.title2" />
        </h4>
        <p>
          <FormattedMessage id="product.analysis.desc2" />
        </p>
      </div>
      <div className={styles.module}>
        <img src={logo3} alt="" />
        <h4>
          <FormattedMessage id="product.analysis.title3" />
        </h4>
        <p>
          <FormattedMessage id="product.analysis.desc3" />
        </p>
      </div>
    </div>
  </div>
)

export default Analysis
