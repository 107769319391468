import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './index.module.css'
import logo from './PGraph2@2x.png'
import logo1 from './pIcon4@2x.png'
import logo2 from './pIcon5@2x.png'
import logo3 from './pIcon6@2x.png'

const Platform = () => (
  <div className={styles.wrapper} id="platform">
    <div className={styles.top}>
      <div className={styles.topText}>
        <h3>
          <FormattedMessage id="product.platform.title" />
        </h3>
        <p>
          <FormattedMessage id="product.platform.slogan" />
        </p>
      </div>
      <img src={logo} alt="onChainVoting" />
    </div>
    <h5>
      <FormattedMessage id="productHighlight" />
    </h5>
    <div className={styles.bottom}>
      <div className={styles.module}>
        <img src={logo1} alt="" />
        <h4>
          <FormattedMessage id="product.platform.title1" />
        </h4>
        <p>
          <FormattedMessage id="product.platform.desc1" />
        </p>
      </div>
      <div className={styles.module}>
        <img src={logo2} alt="" />
        <h4>
          <FormattedMessage id="product.platform.title2" />
        </h4>
        <p>
          <FormattedMessage id="product.platform.desc2" />
        </p>
      </div>
      <div className={styles.module}>
        <img src={logo3} alt="" />
        <h4>
          <FormattedMessage id="product.platform.title3" />
        </h4>
        <p>
          <FormattedMessage id="product.platform.desc3" />
        </p>
      </div>
    </div>
  </div>
)

export default Platform
