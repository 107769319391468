import React from 'react'
import Analysis from '../components/Analysis'
import Layout from '../components/Layout'
import Platform from '../components/Platform'
import ProductBanner from '../components/ProductBanner'
import SDK from '../components/SDK'

const ProductPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale} location={location}>
    <ProductBanner />
    <SDK />
    <Platform />
    <Analysis />
  </Layout>
)

export default ProductPage
